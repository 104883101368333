/** Checkers */
const isEmpty = () => {}
const isDom = (el) => {
    return el instanceof Element;  
}
const isArray = (arr) => {
    return Array.isArray(arr);
}
const isObject = (obj) => {
    return typeof obj === 'object' && obj !== null;
}
const isFullObject = (obj) => {
    return isObject(obj) && arr.length;
}
const isFullArray = (arr) => {
    return isArray(arr) && arr.length;
}
const isNotArray = (arr) => {
    return !isArray(arr);
}
const isNumber = (val) => {
    return val !== null && val !== true && (val >=0 || val < 0);
}
const isString = (val) => {
    return typeof val === 'string'
}
const isVal = (val) => {
    return val !== undefined;
}
const isDifferent = function (valA, valB) {
    let diffValues = true;;
    if (typeof valA === typeof valB) {
        if ((valA !== null && valA instanceof Object) && (valB !== null && valB instanceof Object)) {
            try { diffValues = JSON.stringify(valA) !== JSON.stringify(valB); } catch (err) {}
        } else { diffValues = valA !== valB; }
    }
    return diffValues;
}
const isSame = function (valA, valB) {
    return !isDifferent(valA, valB);
}

/** Stylize processing */
const getStyleSizeInPx = (size) => { 
    let width = 0, height = 0;
    let style = '';
    if (isObject(size)) { 
        width = size.width && isNumber(size.width) ? size.width : 0;
        height = size.height && isNumber(size.height) ? size.height : 0;
    } else if (isNumber(size)) { width = size; height = size; }
    return (width ? `width:${width}px;` : '') + (height ? `height:${height}px;` : '');
} 
const combineCmpStyle = (data = {}) => {
    let { style, size } = data;
    let resStyle = style && isString(style) ? style : '';
    let sizeStyle = getStyleSizeInPx(size);
    resStyle += sizeStyle;
    return resStyle ?  `style="${resStyle}"` : '';
}
const combineCmpCls = ({ mainCls = '', cls = '', addCls, modCls }) => {
    addCls = isArray(addCls) ? addCls.join(' ') : addCls;
    cls += (modCls ? ` ${modCls}` : '') + (addCls ? ` ${addCls}` : '');
    return cls ? `class="${mainCls ? (mainCls + ' ' + cls) : cls}"` : '';
}

/** Object, Array processing */
const getArrayFromObject = (obj) => {
    let arr = [];
    joggOnObject(obj, (it) => arr.push(it) );
    return arr;
}
const getObjectFromArray = (arr, key) => {
    const obj = {};
    let i = 0;
    while (i < arr.length) {
        const it = arr[i];
        obj[it[key]] = it;
        i++;
    }
    return obj;
}
const joggOnArray = (arr = [], fn) => {
    let i = 0;
    let continueJogg = true;
    while (i < arr.length) {
        continueJogg = fn(arr[i], i); 
        if (continueJogg === false) { break; }
        i++;
    }
}
const joggOnObject = (obj, fn) => {
    let i = 0;
    let continueJogg = true;
    for (let key in obj) { 
        continueJogg = fn(key, obj[key], i);
        if (continueJogg === false) { break; }
        i++
    }
}
const findInObject = (obj, compKey, compVal) => {
    let findData = null;
    joggOnObject(obj, (key, val, i) => {
        if (isObject(val) && val[compKey] === compVal) {
           findData = val; 
           return false;
        } else if (key === compKey && val === compVal) {
            findData = val; 
            return false;
        }
    });
    return findData
}

/** DOM, Element processing */
const createElementFromHtml = (html) => {
    const holderEl = document.createElement("div");
    holderEl.insertAdjacentHTML("afterbegin", html);
    return holderEl.firstElementChild;
}
const getChildEls = (el) => {
    return Array.from(el.children);
}
const swapEls = (elA, elB) => {
    const elParentA = elA ? elA.parentNode : null;
    const elParentB = elB ? elB.parentNode : null;
    const hasOneParent = (elParentA && elParentB) && elParentA === elParentB;
 
    if (hasOneParent) {
        let elBeforeA, elBeforeB;
        const els = elParentA ? Array.from(elParentA.childNodes) : [];
        for ( let i=0; i<els.length; i++ ) {
           const el = els[i];
           if (el === elA) { elBeforeA = els[i+1]; }
           if (el === elB) { elBeforeB = els[i+1]; }
           if (elBeforeA && elBeforeB) break;
        }
        if (elBeforeA && elBeforeB) {
            elParentA.insertBefore(elB, elBeforeA);
            elParentB.insertBefore(elA, elBeforeB);
        }
    } else {
        const elsA = Array.from(elParentA.childNodes) || [];
        const elsB = Array.from(elParentB.childNodes) || [];
        const getElBefore = (els, el) => { 
            for ( let i=0; i < els.length; i++ ) {
                if (els[i] === el) { return els[i+1];  }
            }
        };
        const elBeforeA = getElBefore(elsA, elA); 
        const elBeforeB = getElBefore(elsB, elB);
      
        elParentA.insertBefore(elB, elBeforeA); 
        elParentB.insertBefore(elA, elBeforeB);  
    }
}
const scrollToEl = (el) => {
    if (el) {
        const elTop = el.offsetTop;
        window.scrollTo({ top: elTop, behavior: 'smooth'});
    }
}
const scrollUp = (el) => {
    window.scrollTo({ top: 0, behavior: 'smooth'});
}

/** Other */
const useTrottled = ( fn, timeout = 50 ) => {
    let tmr = null; 
    return function () {
        clearTimeout(tmr);
        tmr = setTimeout(() => { fn(); }, timeout);    
    }
}
const createUuid = (difficult) => {
    if (difficult) {
        var val = new Uint32Array(3);
        window.crypto.getRandomValues(val);
        return ( performance.now().toString(36) + Array.from(val).map( function(item) { return item.toString(36); }).join("")).replace(/\./g,"");
    } else {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
}

export {
    isEmpty,
    isObject,
    isDom,
    isArray,
    isFullArray,
    isNotArray,
    isNumber,
    isString,
    isDifferent,
    isSame,
    useTrottled,
    createUuid,
    getStyleSizeInPx,
    combineCmpStyle,
    combineCmpCls,
    getChildEls,
    swapEls,
    joggOnArray,
    joggOnObject,
    findInObject,
    scrollToEl,
    scrollUp,
    getArrayFromObject,
    getObjectFromArray,
    createElementFromHtml
}