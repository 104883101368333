import header from '../header';
import footer from '../footer';
import greeting from './greeting';
import about from './about';
import advantages from './advantages';
import geography from './geography';
import production from './production';
import menu from '../menu';
import { windowFeedback } from '../windows';
import './styles';

const mainPage = () => {
    return `
      ${greeting()}
      ${about()}
      ${advantages()}
      ${geography()}
      ${production()}
      ${footer()}
      ${header()}
      ${menu()}
      ${windowFeedback()}
    `;
}

export default mainPage;
