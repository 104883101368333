import { dishes, recipe, spices } from 'cookery';
import productListData from './productListData';
import './styles';

const { expanderModCTpl } = dishes;
const { iconsHtml } = recipe;
const { joggOnArray } = spices;

const productList = (data ={}) => {
    const getProductCards = () => {
        let html = '';
        joggOnArray(productListData, (it, idx) => {
            html += `<div class="ck-product-item">
              <div class="ck-product-item-line-off">
                <div></div><div></div>
              </div>
              ${it.label}
            </div>`
        });
        return html;
    }
    return `<div class="ck-product-list">${getProductCards()}</div>`;
}

export default productList;