import { chef, dishes, spices, recipe, cookbook } from 'cookery';
import imgLogo from './images/logo.svg';
import './styles';

const { buttonIcoWhatsappTpl, buttonLinedSoftTpl, footerTpl } = dishes;
const { iconsHtml } = recipe;
const { joggOnArray } = spices;

const getMainMenuItems = (data = {}) => {
    const { menuItems } = data;
    let menuItemsHtml = '';
    joggOnArray(menuItems, (it) => {
        const cmp = chef.cookHandler({ 
                  type: 'click', 
                  cretaeCmpType: 'button',
                  defaultHandlers: { defaultsFor: 'button' },
                  fn: () => chef.goTo({ url: it.url })
              });
        menuItemsHtml += `<li id="${cmp.id}"><a>${it.name}</a></li>`;
    });
    return menuItemsHtml;
};
const getPolicyMenuItemHtml = (data = {}) => {
    const btnCmp = chef.cookHandler({
            type: 'click', 
            cretaeCmpType: 'button',
            defaultHandlers: { defaultsFor: 'button' },
            fn: () => chef.goTo({ url: 'privacy-policy', blankPage: true })
        });
    return `<li id="${btnCmp.id}"><a>Политика<br />конфиденциальности</a></li>`;
};

const footer = (initData = {}) => {
    const menuItems = cookbook.get('mainMenu.items');
    const mainMenuItemsHtml = getMainMenuItems({ menuItems });
    const policyMenuItemHtml = getPolicyMenuItemHtml();
    const logoCmp = chef.cookHandler({ type: 'click', fn: () => chef.goToHome() });
    const whatsappPhone = function () {
        const regEx = /[^\d\+]/g;
        return chef.get('contacts.phone').replace(regEx, '');
    }();
    return footerTpl({ ...initData, itemsHtml: `
        <div class="ck-footer-column">
            <div id="${logoCmp.id}" class="ck-footer-logo">
              <img src="${imgLogo}" />
            </div>
            <div class="ck-footer-copyright">
              <div class="ck-line-x"></div>
              <div class="ck-line-x"></div>
              <span>www.</span><a href="${chef.get('mainUrl')}">romanov-house.ru</a> <span>Все права защищены © 2024</span>
            </div>
        </div>
        <div class="ck-footer-column">
          <p class="ck-footer-column-title">Навигация</p>
          <ul class="ck-footer-column-items">
              ${mainMenuItemsHtml}
              ${policyMenuItemHtml}
          </ul>
        </div>
        <div class="ck-footer-column">
          <p class="ck-footer-column-title">Контакты</p>
          <ul class="ck-footer-column-items">
            <li><a href="mailto:${chef.get('contacts.email')}">${chef.get('contacts.email')}</a></li>
            <li>
              <a href="tel:${chef.get('contacts.phone')}">${chef.get('contacts.phone')}</a> 
              <a href="https://wa.me/${whatsappPhone}">${buttonIcoWhatsappTpl()}</a>
            </li>
            <li>${chef.get('contacts.address')}</li>
          </ul>
          ${buttonLinedSoftTpl({ 
            text: 'Cвязаться', 
            handler: { type: 'click', fn: () => cookbook.set('windowFeedbackShow', true) }
          })}
        </div>
    `});
}

export default footer;