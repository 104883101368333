import { chef, dishes, spices, recipe } from 'cookery';
import titleBgroundImg from './images/background.jpg'
import './styles';

const { iconsHtml } = recipe;

const { isObject, isString } = spices;
const { sectionTpl, labelAlertTpl } = dishes;
const getInfoLabelHtml = (data = {}) => {
    let { info } = data;
    if (info) {
        if (isObject(info)) {
            return labelAlertTpl({ ...info, main: true, icoHtml: iconsHtml.icoStar });
        } else if (isString(info)) {
            return labelAlertTpl({ text: info, main: true });
        }
    } else {
        return ''; 
    }
}

const pageTitle = (data = {}) => {
    let { title } = data;
    const infoLabelHtml = getInfoLabelHtml(data);
    return sectionTpl({
        title: '',
        contentHtml: `<div class="ck-page-title">
                        <div class="ck-page-title-text">${title ? title : chef.getPageLabel()}</div>
                        ${infoLabelHtml}
                     </div>`,
        background: { 
            style: `background-image: url(${titleBgroundImg}); background-position: bottom center`,
            contentHtml: '<div></div>'
        },
        addCls: 'ck-page-title-container'
    })
}

export default pageTitle;