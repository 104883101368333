import { chef } from '../../../../../../index';
import { isObject, isString } from '../../../../../../utils';
import { titleSectionTpl } from '../../../titles';
import './styles';

const getMainStyles = (data = {}) => {
  const { cls, addCls, style } = data;
  return chef.useCookedStyles({ cls: cls ? cls : 'ck-section', addCls, style, joint: true });
}
const getBgroundHtml = (data = {}) => {
  const { cls, addCls, style, html, contentHtml,} = data.background || {};
  if (html) { return html; }
  else {
      const styles = chef.useCookedStyles({ cls: cls ? cls : 'ck-section-background', addCls, style, joint: true });
      return `<div ${styles}>${contentHtml ? contentHtml : ''}</div>`;
  }
}
const getContentStyles = (data = {}) => {
  const { cls, addCls, style } = data.content || {};
  return chef.useCookedStyles({ cls: cls ? cls : 'ck-section-content', addCls, style, joint: true });
}
const getTitleData = (title) => {
    return title ? isString(title) ? { text: title } : isObject(title) ? title : {} : {};
}

const sectionTpl = (initData = {}) => {
  let { title, contentHtml } = initData;
  const cmp = chef.cookHandlers({ 
      ...initData, 
      defaultHandlers: { defaultsFor: 'scrolled' } 
  }); 
  const mainStyles = getMainStyles(initData);
  const bgroundHtml = getBgroundHtml(initData);
  const contentStyles = getContentStyles(initData);
  const titleData = getTitleData(title);

  return `<section id="${cmp.id}" ${mainStyles}>
            ${bgroundHtml}
            ${title ? `<div class="ck-section-title-container">${titleSectionTpl(titleData)}</div>` : '' }
            <div ${contentStyles}>${contentHtml ? contentHtml : ''}</div>
         </section>`;
}

export default sectionTpl;