const icoA = `<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.50195 15.751L19.546 34.88L30.352 15.751H8.50195ZM53.999 19.751H31.352L18.568 42.382L29.751 61.751L53.999 19.751Z" fill="#ECAD0A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M34.835 0.496094H34.638L30.811 1.86709V3.35809L33.001 2.67909V9.75109H34.835V0.496094Z" fill="#ECAD0A"/>
</svg>`;

const icoB = `<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.50195 15.751L24.601 43.635L40.352 15.751H8.50195ZM53.999 19.751H41.3521L23.623 51.137L29.751 61.751L53.999 19.751Z" fill="#ECAD0A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.7139 8.2721H32.7399L34.8419 6.0561C35.4169 5.4261 35.8229 4.8731 36.0599 4.3991C36.2969 3.9261 36.4159 3.4601 36.4159 3.0031C36.4159 2.1691 36.1519 1.5241 35.6249 1.0671C35.0989 0.610104 34.3589 0.381104 33.4069 0.381104C32.7849 0.381104 32.2279 0.514104 31.7379 0.778104C31.2469 1.0431 30.8669 1.4081 30.5979 1.8731C30.3289 2.3391 30.1949 2.8531 30.1949 3.4161H32.0359C32.0359 2.9501 32.1549 2.5751 32.3939 2.2891C32.6339 2.0031 32.9599 1.8601 33.3749 1.8601C33.7599 1.8601 34.0559 1.9781 34.2639 2.2131C34.4709 2.4481 34.5749 2.7701 34.5749 3.1811C34.5749 3.4811 34.4769 3.7991 34.2799 4.1331C34.0829 4.4671 33.7789 4.8591 33.3689 5.3071L30.3789 8.4941V9.7511H36.7139V8.2721Z" fill="#ECAD0A"/>
</svg>
`;

const icoC = `<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.50195 15.751L24.601 43.635L40.352 15.751H8.50195ZM53.999 19.751H41.3521L23.623 51.137L29.751 61.751L53.999 19.751Z" fill="#ECAD0A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.245 5.7521H33.217C34.232 5.7521 34.74 6.2131 34.74 7.1351C34.74 7.5161 34.616 7.8221 34.369 8.0531C34.121 8.2831 33.779 8.3991 33.343 8.3991C32.963 8.3991 32.642 8.2891 32.382 8.0681C32.122 7.8481 31.991 7.5651 31.991 7.2181H30.157C30.157 8.0181 30.45 8.6611 31.036 9.1481C31.622 9.6341 32.376 9.8781 33.299 9.8781C34.285 9.8781 35.077 9.6301 35.676 9.1351C36.275 8.6401 36.574 7.9821 36.574 7.1611C36.574 6.6531 36.446 6.2131 36.19 5.8401C35.934 5.4681 35.546 5.1891 35.026 5.0031C35.445 4.8121 35.783 4.5391 36.041 4.1841C36.299 3.8281 36.428 3.4391 36.428 3.0161C36.428 2.1951 36.151 1.5501 35.597 1.0831C35.042 0.615103 34.277 0.381104 33.299 0.381104C32.736 0.381104 32.221 0.489103 31.753 0.705103C31.286 0.921103 30.922 1.2201 30.662 1.6031C30.401 1.9861 30.271 2.4191 30.271 2.9011H32.106C32.106 2.5931 32.224 2.3421 32.461 2.1491C32.698 1.9571 32.992 1.8601 33.343 1.8601C33.733 1.8601 34.039 1.9641 34.261 2.1711C34.483 2.3791 34.594 2.6691 34.594 3.0411C34.594 3.4261 34.482 3.7351 34.258 3.9681C34.033 4.2011 33.688 4.3171 33.223 4.3171H32.245V5.7521Z" fill="#ECAD0A"/>
</svg>`;

const icoD = `<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.50195 15.751L19.546 34.88L30.352 15.751H8.50195ZM53.999 19.751H31.352L18.568 42.382L29.751 61.751L53.999 19.751Z" fill="#ECAD0A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.806 0.508057H33.953L30.1 6.59606L30.182 7.75106H33.972V9.75106H35.806V7.75106H36.854V6.27206H35.806V0.508057ZM33.851 3.21906L33.972 3.00906V6.27206H31.928L33.851 3.21906Z" fill="#ECAD0A"/>
</svg>
`;

export {
    icoA,
    icoB,
    icoC,
    icoD
}