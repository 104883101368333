import { chef } from '../../../../../index';
import { buttonIcoTpl } from '../../buttons';
import iconsHtml from './../../../../images/icons/html'
import './styles';

const getMainStyles = (data = {}) => {
    const { cls, addCls, style } = data;
    return chef.useCookedStyles({ cls: cls ? cls : 'ck-footer', addCls, style, joint: true });
}

const footerTpl = (initData = {}) => {
    let { cmp, id, itemsHtml } = initData;
    const mainCmp = cmp ? cmp : chef.cookCmp({ id }); 
    const mainStyles = getMainStyles(initData);
    const scrollUpBtnCmp = chef.cookHandler({ 
        type: 'click',
        fn: () => chef.scrollUp(),
        defaultHandlers: { defaultsFor: ['button', 'scrolled'] },
        createCmpType: 'button',
              
    })


    return `<div id="${mainCmp.id}" ${mainStyles}>
               <div class="ck-footer-background"></div>
               <div class="ck-footer-content">
                  ${itemsHtml ? itemsHtml : ''}
               </div>
               ${buttonIcoTpl({ cmp: scrollUpBtnCmp, addCls: 'ck-footer-button-scroll-up', icoHtml: iconsHtml.icoUp })}
           </div>`
}

export  default footerTpl;