import { chef, dishes, recipe } from 'cookery'
import imgAbstartc from './images/abstract.png';
import './styles';

const { sectionTpl, sliderTpl, buttonTpl } = dishes;
const { iconsHtml } = recipe;

const mainSlider = () => {
    return sliderTpl({
                controls: true,
                slideEffect: 'slideX',
                slideTimeout: true,
                addCls: 'ck-greeting-slider',
                slides: [
                    { html: `<div class="ck-greeting">
                                <div class="ck-greeting-background"><div></div><div></div><div></div></div>
                                <div class="ck-greeting-info-container">
                                    <div class="ck-greeting-info">
                                        <h1>Упрощаем сложное!</h1>
                                        <p>Ваш надежный партнер в области кабельно-проводниковой продукции <span>для задач</span> любой сложности</p>
                                        ${buttonTpl({ 
                                            text: 'Подробнее', 
                                            icoHtml: iconsHtml.icoDown, 
                                            handler: { type: 'click', fn: () => chef.scrollTo({ queryEl: '#about'}) },
                                            addCls: 'ck-button-greeting'
                                        })}
                                    </div>
                                    <div class="ck-greeting-info-image">
                                    <img src="${imgAbstartc}" />
                                    </div>
                                </div>
                            </div>` 
                    }
                ],
            });
}

const greeting = () => {
    return sectionTpl({ contentHtml: mainSlider(), addCls: 'ck-greeting-section', disableDefaultHandlers: true });
}

export default greeting;