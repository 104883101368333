import { dishes } from 'cookery';
import productList from '../../productList';
import './styles';

const { sectionTpl } = dishes;

const production = () => {
    return sectionTpl({ 
        title: { text: 'Продукция', size: 'l', color: 'black' }, 
        addCls: 'ck-production', 
        contentHtml: productList()
    });
}

export default production;