import { dishes, recipe, cookbook } from 'cookery';
import imgMap from './images/map.png';
import './styles';

const { sectionTpl, buttonTpl, titleSectionTpl } = dishes;

const geography = () => {
    const html = `<div class='ck-geography-content'>
                <div class="ck-geography-info">
                    ${titleSectionTpl({ text: 'Ваш надежный партнер по всей стране', size: 'l', color: 'black' })}
                    <p> Компания Торговый дом "Романов" эффективно работает с производителями из каждого уголка России, не признавая границ. Наша гордость - способность обслуживать клиентов по всей огромной территории страны.<p/>
                    <p>Наши многочисленные успешно реализованные проекты в различных регионах подтверждают высокий уровень нашего профессионализма и надежности. Мы всегда готовы преодолеть любые препятствия, чтобы удовлетворить потребности наших заказчиков.<p/>
                    ${buttonTpl({ 
                        text: 'Связаться', 
                        handler: { type: 'click', fn: () => cookbook.set('windowFeedbackShow', true) }
                    })} 
                </div>
                <div class="ck-geography-image"><img src="${imgMap}" /></div>
              </div>`;

    return sectionTpl({ 
        contentHtml: html, 
        addCls: 'ck-geography',
     });
}

export default geography;