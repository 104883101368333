import { beginCook } from 'cookery';
import { load as loadRecaptcha } from 'recaptcha-v3';
import './sass';
import './sass/global';
import {
    main,
    production,
    contacts,
    privacyPolicy
} from './pages';

beginCook({
   rootElement: document.querySelector('#app'),
   initialDataModel: {
        mainUrl: 'https://www.romanovtd.ru',
        pageLoading: true, 
        maskModalShow: false,
        menuModalShow: false,
        windowFeedbackShow: false,
        contacts: {
            email: 'info.tdromanov@gmail.com',
            phone: '7 (988) 240-22-20',
            address: '350067, г. Краснодар, ул. 9-я Тихая, д. 25, оф. 79'
        },
        mainMenu: {
        minimized: false,
        items: [
                { name: 'Главная', url: 'home', active: true },
                { name: 'Продукция', url: 'production', active: false },
                { name: 'Контакты', url: 'contacts', active: false }
            ]
        },
        recaptcha: { key: '6LdMr3EpAAAAAPCy4twgzG1A9c5g7xIGiWt3dsKh' }
    },
    initialExtraControllers: {
        recaptchaHandler: (e) => {
            return new Promise((resolve, reject) => {
                e.preventDefault;
                loadRecaptcha('6LdMr3EpAAAAAPCy4twgzG1A9c5g7xIGiWt3dsKh').then((recaptcha) => {
                    recaptcha.execute('submit').then((token) => { 
                        resolve(token);
                    }).catch((err) => { resolve(false); });
                }).catch((err) => { resolve(false); });
            });
        }
    },
    initialPages: {       
        'main': {
            title: 'Торговый дом "Романов" - Главная', 
            name: 'Главная',
            content: main,
            default: true,
            active: false,
        },
        'production': {
            title: 'Торговый дом "Романов" - Продукция',
            name: 'Продукция',
            content: production,
            default: false,
            active: false,
        },
        'contacts': {
            title: 'Торговый дом "Романов" - Контакты',
            name: 'Контакты',
            content: contacts,
            default: false,
            active: false,
        },
        'privacy-policy': {
            title: 'Торговый дом "Романов" - Политика конфиденциальности',
            name: 'Политика конфиденциальности',
            content: privacyPolicy,
            extra: true
        }
    }
});