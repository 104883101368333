const api = {
    requestFeedback: (data = {}) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            const message = function () {
                return `Имя: ${data.name}
                  <br/>Телефон: ${data.phone || 'Не указано...'}
                  <br/>email: ${data.email || 'Не указано...'}
                  <br/>Сообщение:${data.message || 'Сообщение отсутствует...'}`;
            }();

            formData.set('from', 'romanov@mooratest.ru');
            formData.set('subj', 'Обратная связь с сайта romanovtd.ru');
            formData.set('to', 'info.tdromanov@gmail.com');
            formData.set('msg', message);
            formData.append('submit', 'true');
            grecaptcha.ready(() => {
                grecaptcha.execute('6Lf4Da0pAAAAAOL7e4SXBSny7jzi7s9G8h9PrV_m', { action: 'submit' }).then( (token) =>{
                    formData.set('g-recaptcha-response', token);
                    fetch('/mailrecaptched.php', { method: 'POST', body: formData })
                    .then( (resp) => resp.json())
                    .then((data) => { resolve(data); })
                    .catch((err) => { reject(err); })
                });
            });
        });
    }
}

export default api;