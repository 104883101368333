import icoClose from './ico-close';
import icoDown from './ico-down';
import icoUp from './ico-up';
import icoElectric from './ico-electric';
import icoFeedback from './ico-feedback';
import icoRight from './ico-right';
import icoLeft from './ico-left';
import icoStriped from './ico-striped';
import icoBurger from './ico-burger';
import icoWhatsapp from './ico-whatsapp';
import icoLogo from './ico-logo';
import icoLogoA from './ico-logo-a';
import icoSend from './ico-send';
import icoLoader from './ico-loader';
import icoExclame from './ico-exclame';
import icoGroup from './ico-group';
import { icoLink, icoLinkA } from './ico-link';

const iconsHtml = {
    icoClose,
    icoDown,
    icoUp,
    icoElectric,
    icoFeedback,
    icoRight,
    icoLeft,
    icoStriped,
    icoBurger,
    icoLink,
    icoLinkA,
    icoWhatsapp,
    icoLogo,
    icoLogoA,
    icoLoader,
    icoExclame,
    icoSend,
    icoGroup
}

export default iconsHtml;