import { chef } from '../../../../../index';
import classNames from '../../../../classNames';
import './styles';

const { templates: { titleSection: titleClassNames }} = classNames;
const getMainCls = (data = {}) => {
    const { cls, addCls, modCls, colorCls, alignCls, sizeCls, style } = data;
    let mainCls = cls ? cls : `${titleClassNames.title}`;
    mainCls += ` ${modCls} ${colorCls} ${alignCls} ${sizeCls}`;
    return chef.useCookedStyles({ cls: mainCls, addCls, style, joint: true });
};

const titleSectionTpl = (initData = {}) => {
    const { text, align, color, size, other } = initData;
    const { numb } = other || {}
    const alignCls = { right: 'ck-align-right', center: 'ck-align-center' }[align] || ''; /* Align left is default */
    const colorCls = chef.getClsNamesMap('colors')[color] || ''; /* Main color is default */
    const sizeCls = { s: 'ck-size-s', m: 'ck-size-m', l: 'ck-size-l', xl: 'ck-size-xl' }[size];
    const mainCls = getMainCls({...initData, colorCls, alignCls, sizeCls });
    
    return `<div ${mainCls}>
                <h2 class="${titleClassNames.text}">
                    ${text ? text : '...'}
                    <div class="ck-title-decoration">
                        <div></div><div></div>
                        <div>${numb ? numb : ''}</div>
                    </div>
                  </h2>
              <div class="${titleClassNames.tools}" ></div>
          </div>`
}

const titleSectionModATpl = (initData = {}) => {   
    return titleSectionTpl({ ...initData, modCls: 'ck-mod-a'});
}

export { titleSectionTpl, titleSectionModATpl }