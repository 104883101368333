import { chef, dishes } from 'cookery';
import './styles';

const { sectionTpl, buttonLinkSoftTpl, infoCardTpl } = dishes;

const contacts = (data = {}) => {
    return sectionTpl({ 
        addCls: 'ck-contacts',
        contentHtml: `<div class="ck-contact-cards">
                ${infoCardTpl({
                    title: 'Контакты (общие/отдел продаж):',
                    itemsData: {
                        items: [
                            { name: 'email:', value: `<a href="mailto:info.tdromanov@gmail.com">info.tdromanov@gmail.com</a>` },
                            { name: 'email:', value: `<a href="mailto:td.romanov.rs@mail.ru">td.romanov.rs@mail.ru</a>` },
                            { name: 'телефон:', value: `<a href="tel:+79882402220">+7 (988) 240-22-20</a>` },
                            { name: 'телефон:', value: `<a href="tel:+78612402220">+7 (861) 240-22-20</a>` },
                            { name: 'whatsapp:', value: `<a href="https://wa.me/+79882402220">${buttonLinkSoftTpl({ text: '+7 (988) 240-22-20'})}</a>` },
                            { name: 'юр. адрес:', value: '350067, г. Краснодар, ул. 9-я Тихая, д. 25, оф. 79' }
                        ]
                    }
                })}
                ${infoCardTpl({
                    title: 'Контакты (бухгалтерия):',
                    itemsData: {
                        items: [
                            { name: 'телефон:', value:  `<a href="tel:+79282284532">+7 (928) 228-45-32</a>` },
                            { name: 'whatsapp:', value: `<a href="https://wa.me/+79282284532">${buttonLinkSoftTpl({ text: '+7 (928) 228-45-32'})}</a>` }
                        ]
                    }
                })}
            </div>
            <div class="ck-contacts-address-map">
            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A7caa81d28de48dc9e8e96452bbb501c15d0ec3f6d2103c115937ecad8a0a0534&amp;source=constructor" width="1410" height="440" frameborder="0"></iframe>
            </div>` 
        });
}

export default contacts;