import {
    icoA,
    icoB,
    icoC,
    icoD
} from './icons';

export default [
    { title: 'Обеспечение комплексного подхода к потребностям заказчика', ico: icoA, info: `Наши интегрированные решения включают поставки оборудования первого и второго уровней для объектов в сферах тепло- и электроэнергетики, а также в области капитального строительства и технического обновления.` },
    { title: 'Проработка организационно-логистической схем поставки', ico: icoB, info: `Мы гарантируем нашим клиентам высококачественный сервис: надежные сроки поставок, превосходную логистику и полный спектр услуг для эффективного запуска проектов "под ключ".` },
    { title: 'Поставка продукции высокого качества', ico: icoC, info: `За годы работы мы наработали значительный опыт сотрудничества с ведущими производителями кабельно-проводниковой продукции, электротехнических изделий и оборудования для сектора энергетики.` },
    { title: 'Одна точка контакта', ico: icoD, info: `Мы берем на себя координацию работы с различными производителями, поставщиками и услугами, упрощая взаимодействие для наших клиентов и экономя их время и ресурсы` }
]
