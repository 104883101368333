import { chef, dishes } from 'cookery'
import info from './info';
import './styles';

const { sectionTpl } = dishes;

const advantageCard = (data) => {
    return `<div class="ck-card">
              <div class="ck-ico-container">${data.ico}</div>
              <div class="ck-info-container">
                <h3>${data.title}</h3>
                <p>${data.info}</p>
              </div>
            </div>`
};

const advantageCards = () => {
    return `<div class="ck-advantage-cards">
               ${info.reduce((html, it) => { html += advantageCard(it); return html; }, '')}
            </div>`
};

const adwantages = (initData = {}) => {
    return sectionTpl({ 
      title: { text: 'Преимущества', color: 'white', size: 'l'}, 
      addCls: 'ck-advantages', 
      contentHtml: advantageCards(),
      background: { addCls: 'ck-advantages-background'},
    });
};

export default adwantages;