export default [
    { label: 'Комплектные распределительные устройства (КРУ,КРУЭ, КРУН, КСО) <span>до 500 кВ</span>'},
    { label: 'Силовые трансформаторы напряжения <span>до 500 кВ</span>'},
    { label: 'Высоковольтные выключатели <span>до 500 кВ</span>'},
    { label: 'Высоковольтные разъединители <span>до 500 кВ</span>'},
    { label: 'Высоковольтные вводы <span>до 500 кВ</span>'},
    { label: 'Трансформаторы тока и напряжения <span>до 330 кВ</span>'},
    { label: 'Комплектные трансформаторные подстанции (КТП, КТПНУ, БКТП) <span>до 35 кВ</span>'},
    { label: 'Щиты и шкафы распределительные'},
    { label: 'РЗА (устройства релейной защиты и автоматики)'},
    { label: 'ПА (противоаварийная автоматика)'},
    { label: 'СОПТ (системы оперативного постоянного тока)'},
    { label: 'Дизель-генераторные установки'},
    { label: 'Высоковольтная кабельно-проводниковая продукция с арматурой <span>от 110 кВ до 330 кВ</span>'},
    { label: 'Кабельно-проводниковая продукция <span>до 35 кВ</span> (силовые, контрольные, специальные кабеля, неизолированный провод, СИП и пр.)'},
    { label: 'Кабельно-проводниковая арматура <span>до 35 кВ</span>'},
    { label: 'Изоляторы (стеклянные, фарфоровые, полимерные)'},
    { label: 'Сетевой железобетон (стойки, фундаменты, приставки, ригели, лотки и пр.)'},
    { label: 'Деревянные опоры'},
    { label: 'Металлические опоры (решётчатые, многогранные, из гнутого профиля) и винтовые фундаменты'},
    { label: 'Защитная аппаратура до 330 кВ (ограничители перенапряжения, предохранители, разрядники, заземлители, короткозамыкатели)'},
    { label: 'Оборудование для систем связи'},
    { label: 'Оптический кабель для ВОЛС'},
    { label: 'Грозотрос (в том числе с встроенным оптическим волокном) и стальные канаты'},
    { label: 'Кабеленесущие системы'},
    { label: 'Электроизмерительная аппаратура'},
    { label: 'Светотехническое оборудование'},
    { label: 'Опоры и мачты освещения'},
    { label: 'Резервуары вертикальные и горизонтальные, подземные ёмкости, резервуарное оборудование'},
    { label: 'Средства и системы автоматизации в сфере энергетической промышленности'},
]