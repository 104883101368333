import { dishes } from 'cookery';
import './styles';
import imgAbstract from './images/abstract.png'

const { sectionTpl } = dishes;

const html = `
    <div class="ck-about-info">
        <div class="ck-about-info-image">
          <img src="${imgAbstract}" />
        </div>
        <div class="ck-about-info-text">
          <p>Мы специализируемся на поставках кабельно-проводниковой и электротехнической продукции для различных применений. Наши решения важны для энергетической отрасли, предоставляя материалы для строительства и модернизации кабельных линий. В сфере строительства мы предлагаем продукцию для энергоснабжения как гражданских, так и промышленных объектов.</p>
          <p>В металлургии наш выбор включает продукты как для общего, так и специализированного использования в металлургическом производстве. Для нефтегазовой отрасли у нас есть кабельно-проводниковая продукция, выдерживающие экстремальные условия, включая питание насосов нефтескважин.</p>
          <p>Мы также поддерживаем транспортную инфраструктуру, предлагая решения для подвижного состава рельсового транспорта и троллейбусов. В машиностроении наша продукция помогает в энергоснабжении предприятий. Для горнодобывающей отрасли мы предоставляем решения, обеспечивающие шахты и разрезы надежным электроснабжением.</p>
          <p>Наши продукты адаптированы для конкретных отраслей, обеспечивая их специфическими требованиями</p> 
        </div>

    </div>
`;
const about = () => {
    return sectionTpl({ id: 'about', contentHtml: html, title: { text: 'О нас и наших поставках', size: 'l', color: 'black' }, addCls: 'ck-about' });
}

export default about;