import  { chef, dishes, spices, recipe }  from 'cookery';
import './styles';

const { menuModalTpl, buttonLinedSoftTpl } = dishes;
const { isObject, joggOnObject } = spices;
const { classNames, iconsHtml } = recipe;
const { templates: { menuModal: menuModalClsNames, overlapper: overlapperClsNames }} = classNames;

const getMenuItemsData = (items) => {
    let itemsHtml ='';
    if (isObject(items)) {
        joggOnObject(items, (key, it) => { 
            const cmp = chef.cookHandler({ type: 'click', defaultHandlers: { defaultsFor: 'button' }, fn: () => chef.goTo({ url: key }) });
            itemsHtml += !it.extra ? `<li id="${cmp.id}" class="${menuModalClsNames.item}">${it.name}</li>` : ''
        });
        if (itemsHtml) {
            itemsHtml = `<ul class="${menuModalClsNames.items}">${itemsHtml}</ul>`
        }
        return itemsHtml;
    } else { return ''; }
};
const getFeedbackBtnHtml = () => {
    return buttonLinedSoftTpl({ 
        text: 'Связаться', 
        addCls: 'ck-button-feedback', 
        icoHtml: iconsHtml.icoFeedback, 
        handler: { 
            type: 'click', 
            fn: () => { 
              chef.set('windowFeedbackShow', true);
              chef.set('menuModalShow', false);
            }
        }
    });
}

const menu = () => {
    const itemsHtml = getMenuItemsData(chef.getPages());
    const feedbackBtnHtml = getFeedbackBtnHtml();

    return menuModalTpl({ itemsData: { html: itemsHtml + feedbackBtnHtml }, binds: { show: 'menuModalShow' } });
};

export default menu;