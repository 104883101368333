import { chef } from '../../../../../index';
import { isFullArray, joggOnArray } from '../../../../../utils';
import { buttonLinedSoftTpl } from '../../buttons';
import iconsHtml from '../../../../../theme/images/icons/html';

const createButtonCmp = (config = {}) => {
    let { cmp, formCmp, id, btnType, useRecaptcha, text, btnTpl, btnColor, txtColor } = config; 
    const btnCmp = cmp ? cmp : chef.cookCmp({ ...config, id, type: 'button' });
    const onClick = () => { if (btnCmp.enabled) { formCmp.submit(); } };
    const onClickRecaptcha = (e) => { if (btnCmp.enabled) { formCmp.submit(); }}
    btnTpl = btnTpl ? btnTpl : buttonLinedSoftTpl;
    if (btnType === 'submit') {
        btnCmp.setRole('formsubmit');
        return [ 
            btnCmp, 
            btnTpl({ 
                cmp: btnCmp,
                text: text || 'Отправить', 
                icoHtml: iconsHtml.icoSend, 
                addCls: 'ck-disabled',
                btnColor, 
                txtColor,
                handler: { 
                    type: 'click', 
                    fn: useRecaptcha !== false ? onClickRecaptcha : onClick
                }
            })
        ];
    } else {
        return [ null, '' ];
    }
};

const getButtons = (data = {}) => {
    const { formCmp, buttonsConfig = {} } = data;
    const { defaults, customs, html } = buttonsConfig;
    let htmlCollect = ''; 
    let cmpsByRole = {
        other: [],
        submit: []
    };
    const hasCustomButtons = isFullArray(customs);
    const hasDefaultButtons = isFullArray(defaults);

    if (html) {
        return [ html, {} ];
    } else if (hasCustomButtons) {
        joggOnArray(customs, (config) => { 
            htmlCollect += config.html;
            if (cmp.getRole() === 'formsubmit') {
                cmpsByRole.submit.push(cmp);
            } else {
                cmpsByRole.other.push(cmp);
            }
        });
        return [ htmlCollect, cmpsByRole ];
    } else if (hasDefaultButtons) {
        joggOnArray(defaults, (config) => { 
            const [ cmp, html ] = createButtonCmp({...config, formCmp: config.formCmp || formCmp });
            htmlCollect += html;
            if (cmp.getRole() === 'formsubmit') {
                cmpsByRole.submit.push(cmp);
            } else {
                cmpsByRole.other.push(cmp);
            }
        }); 
        return [ htmlCollect, cmpsByRole ];
    } else {
        return [ '', {} ];
    }
}

export { getButtons }