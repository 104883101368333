import { chef, dishes, spices, recipe, cookbook } from 'cookery';
import './styles';

const { useTrottled } = spices;
const { buttonLinedTpl, buttonIcoBurgerTpl } = dishes;
const { iconsHtml } = recipe;

const header = () => {
    const logoCmp = chef.cookHandler({ type: 'click', fn: () => chef.goToHome() });
    const menuItems = chef.get('mainMenu.items') || [];
    const headerCmp = chef.cookCmp();
    const menuLinksCmp = chef.cookCmp();
    const btnBurgerCmp = chef.cookCmp();
    const menuLinksHtml = menuItems.reduce(( html, it) => { 
        const cmp = chef.cookHandler({ type: 'click', defaultHandlers: { defaultsFor: 'button' }, fn: () => chef.goTo({ url: it.url }) });
        return html += `<a id="${cmp.id}">${it.name}<div class="underline"></div></a>`;
    }, '');
    const controllMenuType = () => {
        const winWidth = window.innerWidth;
        if (winWidth <= 700) {
            cookbook.set('mainMenu.minimized', true );
        } else {
            const viewWidth = menuLinksCmp.getWidth();
            const scrollWidth = menuLinksCmp.getElsWidth();
            cookbook.set('mainMenu.minimized', viewWidth < scrollWidth );
        }
    };
    const showWindowFeedback = () => chef.set('windowFeedbackShow', true);
    const showMenuModal = () => chef.set('menuModalShow', true);
    
    chef.bind('mainMenu.minimized', (vl) => {  
        if (vl) { headerCmp.addCls('minimized'); } else { headerCmp.removeCls('minimized'); }
    });
    chef.cookHandlers({
        cmp: menuLinksCmp,
        handlers: [
            { type: 'render', delay: 20, fn: controllMenuType },
            { type: 'windowresize', delay: 100, fn: controllMenuType }
        ]
    });
    return (
        `<div id="${headerCmp.id}" class="ck-header">
          <div class="ck-header-content">
            <div id="${logoCmp.id}" class="ck-header-logo">
               <div id="${logoCmp.id}"></div>
               <!--<div class="ck-line-y"></div>
               <p>Центр энергетики,<br/>строительства и<br/>комплектации</p>!-->
            </div>
            <div id="${menuLinksCmp.id}" class="ck-hedaer-menu-links">${menuLinksHtml}</div>
            ${buttonLinedTpl({ 
                text: 'Связаться', 
                addCls: 'ck-button-feedback', 
                handler: { type: 'click', fn: showWindowFeedback } 
            })}
            ${buttonIcoBurgerTpl({ id: btnBurgerCmp.id, addCls : 'ck-button-ico-burger', handler: { type: 'click', fn: showMenuModal } })}
          </div>
        </div>`
    );
}

export default header;